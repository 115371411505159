import { TFunction } from 'next-i18next';

import { BackgroundTypes } from 'types/app';
import { externalRoutes, Page, PageSections, pageTitles, RedirectType, routes } from 'types/pages';
import { UserFlags } from 'types/user';

export const seeAllUserFlags: UserFlags = {
  showFundingReport: true,
  showIntegrations: true,
  showHiringAndOnBoarding: true,
  showTimeAndAttendance: true,
  showBenefitsAdministration: true,
  showOnlineStore: true,
  showBillPay: true,
  showAccountSettings: true,
  showSGASimulator: true,
  showCTACard: true,
  showPermissionOverride: true,
  showImpersonateUser: true,
  showStatementReport: true,
  showMyEquipment: true,
  showConfiguration: true,
};

const commonNs = 'common';

// this is largely just a constant that is only used in a hook for translation purposes
// it doesn't need testing
/* istanbul ignore next */
export function getAllApplicationPages(t: TFunction, flags: UserFlags = seeAllUserFlags) {
  const getTranslation = (name: string) => t(name, { ns: commonNs });

  const applicationPages: Array<Page> = [
    {
      path: routes.home,
      title: getTranslation(pageTitles.home),
      icon: 'fa-regular fa-house',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: true,
      isConfigurable: true,
      isLocked: true,
    },
    {
      path: routes.paymentsPlusGroup,
      title: getTranslation(pageTitles.paymentsPlus),
      icon: 'fa-regular fa-money-bill-transfer',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: true,
      isConfigurable: true,
      children: [
        {
          path: routes.paymentsPlus,
          securedExternalRoute: externalRoutes.paymentsDashboard,
          title: getTranslation(pageTitles.paymentManagerPlus),
          parent: routes.paymentsPlusGroup,
          parentTitle: getTranslation(pageTitles.paymentsPlus),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisibleByFlag: true,
          isConfigurable: true,
          redirectType: RedirectType.CurrentWindow,
        },
        {
          path: routes.store,
          title: getTranslation(pageTitles.integrations),
          parent: routes.paymentsPlusGroup,
          parentTitle: getTranslation(pageTitles.paymentsPlus),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisibleByFlag: flags.showIntegrations,
          isConfigurable: true,
        },
        {
          path: routes.billPay,
          title: getTranslation(pageTitles.billPay),
          parent: routes.paymentsPlusGroup,
          parentTitle: getTranslation(pageTitles.paymentsPlus),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisibleByFlag: flags.showBillPay,
          isConfigurable: true,
        },
        {
          path: routes.ach,
          title: getTranslation(pageTitles.achReporting),
          parent: routes.paymentsPlusGroup,
          parentTitle: getTranslation(pageTitles.achReporting),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisibleByFlag: true,
          isConfigurable: true,
        },
        {
          path: routes.inPerson,
          title: getTranslation(pageTitles.inPerson),
          parent: routes.paymentsPlusGroup,
          parentTitle: getTranslation(pageTitles.paymentsPlus),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisibleByFlag: true,
          isConfigurable: true,
        },
      ],
    },
    {
      path: routes.pointOfSaleGroup,
      title: getTranslation(pageTitles.pointOfSale),
      icon: 'fa-regular fa-credit-card',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: true,
      isConfigurable: true,
      children: [
        {
          path: routes.restaurant,
          title: getTranslation(pageTitles.restaurant),
          parent: routes.pointOfSaleGroup,
          background: BackgroundTypes.Default,
          parentTitle: getTranslation(pageTitles.pointOfSale),
          isVisibleByFlag: true,
          isConfigurable: true,
          section: getTranslation(PageSections.QuickLinks),
        },
        {
          path: routes.retail,
          title: getTranslation(pageTitles.retail),
          parent: routes.pointOfSaleGroup,
          background: BackgroundTypes.Default,
          parentTitle: getTranslation(pageTitles.pointOfSale),
          isVisibleByFlag: true,
          isConfigurable: true,
          section: getTranslation(PageSections.QuickLinks),
        },
      ],
    },
    {
      path: routes.payrollPlusGroup,
      title: getTranslation(pageTitles.payrollPlus),
      icon: 'fa-regular fa-hand-holding-dollar',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: true,
      isConfigurable: true,
      children: [
        {
          path: routes.payrollPlus,
          securedExternalRoute: externalRoutes.payrollPlus,
          parent: routes.payrollPlusGroup,
          parentTitle: getTranslation(pageTitles.payrollPlus),
          title: getTranslation(pageTitles.payroll),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisibleByFlag:
            flags.showBenefitsAdministration || flags.showHiringAndOnBoarding || flags.showTimeAndAttendance,
          isConfigurable: true,
          redirectType: RedirectType.NewTab,
        },
        {
          path: routes.hiringAndOnboarding,
          securedExternalRoute: externalRoutes.payrollHiringAndBoarding,
          parent: routes.payrollPlusGroup,
          parentTitle: getTranslation(pageTitles.payrollPlus),
          title: getTranslation(pageTitles.payrollHiringAndBoarding),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisibleByFlag: flags.showHiringAndOnBoarding,
          isConfigurable: true,
          redirectType: RedirectType.NewTab,
        },
        {
          path: routes.timeAndAttendance,
          securedExternalRoute: externalRoutes.payrollTimeAndAttendance,
          title: getTranslation(pageTitles.payrollTimeAndAttendance),
          parent: routes.payrollPlusGroup,
          parentTitle: getTranslation(pageTitles.payrollPlus),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisibleByFlag: flags.showTimeAndAttendance,
          isConfigurable: true,
          redirectType: RedirectType.NewTab,
        },
        {
          path: routes.benefitsAdministration,
          securedExternalRoute: externalRoutes.payrollBenefitsAdministration,
          title: getTranslation(pageTitles.payrollBenefitsAdministration),
          parent: routes.payrollPlusGroup,
          parentTitle: getTranslation(pageTitles.payrollPlus),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisibleByFlag: flags.showBenefitsAdministration,
          isConfigurable: true,
          redirectType: RedirectType.NewTab,
        },
      ],
    },
    {
      path: routes.loans,
      title: getTranslation(pageTitles.capital),
      icon: 'fa-regular fa-sack-dollar',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: true,
      isConfigurable: true,
    },
    {
      path: routes.reports,
      title: getTranslation(pageTitles.reports),
      icon: 'fa-regular fa-chart-mixed',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: true,
      isConfigurable: true,
      isLocked: true,
    },
    {
      path: routes.myEquipment,
      title: getTranslation(pageTitles.myEquipment),
      icon: 'fa-regular fa-cash-register',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: flags.showMyEquipment,
      isConfigurable: true,
      isLocked: true,
    },
    {
      path: routes.disputes,
      title: getTranslation(pageTitles.disputes),
      icon: 'fa-regular fa-legal',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: true,
      isConfigurable: true,
      isLocked: true,
    },
    {
      path: routes.userManagement,
      title: getTranslation(pageTitles.userManagement),
      icon: 'fa-regular fa-id-badge',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisibleByFlag: true,
      isConfigurable: true,
      isLocked: true,
    },
    {
      path: routes.equipment,
      title: getTranslation(pageTitles.equipment),
      icon: 'fa-regular fa-basket-shopping',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisibleByFlag: true,
      isConfigurable: true,
    },
    {
      path: routes.equipmentDetails,
      title: getTranslation(pageTitles.equipment),
      parent: routes.equipment,
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisibleByFlag: true,
    },
    {
      path: routes.checkout,
      title: getTranslation(pageTitles.checkout),
      parent: routes.equipment,
      parentTitle: getTranslation(pageTitles.equipment),
      icon: 'cart',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisibleByFlag: true,
    },
    {
      path: routes.batchesAndTransactions,
      title: getTranslation(pageTitles.batchesAndTransactions),
      parent: routes.reports,
      parentTitle: getTranslation(pageTitles.reports),
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: true,
    },
    {
      path: routes.statementReport,
      title: getTranslation(pageTitles.statementReport),
      parent: routes.reports,
      parentTitle: getTranslation(pageTitles.reports),
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: true,
    },
    {
      path: routes.ach,
      title: getTranslation(pageTitles.achReporting),
      parent: routes.reports,
      parentTitle: getTranslation(pageTitles.reports),
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: true,
    },
    {
      path: routes.funding,
      title: getTranslation(pageTitles.funding),
      parent: routes.reports,
      parentTitle: getTranslation(pageTitles.reports),
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: true,
    },
    {
      path: routes.batchDetails,
      title: getTranslation(pageTitles.batchesAndTransactions),
      parent: routes.batchesAndTransactions,
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisibleByFlag: true,
    },
    {
      path: routes.accountSettings,
      title: getTranslation(pageTitles.accountSettings),
      icon: 'fa-regular fa-gear',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisibleByFlag: flags.showAccountSettings,
      isConfigurable: true,
      isLocked: true,
    },
    {
      path: routes.support,
      title: getTranslation(pageTitles.helpAndSupport),
      icon: 'fa-regular fa-life-ring',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisibleByFlag: true,
      isConfigurable: true,
      isLocked: true,
    },
    {
      path: routes.sgaSimulator,
      title: getTranslation(pageTitles.sgaSimulator),
      icon: 'fa-regular fa-alien',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.PowerTools),
      isVisibleByFlag: flags.showSGASimulator,
    },
    {
      path: routes.permissionOverride,
      title: getTranslation(pageTitles.permissionOverride),
      icon: 'fa-regular fa-masks-theater',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.PowerTools),
      isVisibleByFlag: flags.showPermissionOverride,
    },
    {
      path: routes.impersonateUser,
      title: getTranslation(pageTitles.impersonateUser),
      icon: 'fa-regular fa-person-to-portal',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.PowerTools),
      isVisibleByFlag: flags.showImpersonateUser,
    },
    {
      path: routes.configuration,
      title: getTranslation(pageTitles.configuration),
      icon: 'fa-regular fa-sliders',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.PowerTools),
      isVisibleByFlag: flags.showConfiguration,
    },
  ];

  return applicationPages;
}
